<template>
  <div>
    <div class="mb-12">
      <slot/>
    </div>
    <footer
      class="flex flex-col md:flex-row fixed bottom-0 justify-between items-center w-full px-8 py-4 border-t-2 bg-white"
    >
      <div class="text-gray-500 text-sm text-center md:text-left">
        MANYCONTENT · CNPJ: 31.808.647/0001-78 · {{new Date().getFullYear()}} © Todos os Direitos Reservados
      </div>
      <div class="space-x-4">
        <a href="https://www.instagram.com/manycontent" target="_blank" rel="noopener noreferrer">
          <i class="fab fa-instagram"></i>
        </a>
        <a href="https://www.facebook.com/manycontent" target="_blank" rel="noopener noreferrer">
          <i class="fab fa-facebook"></i>
        </a>
        <a href="https://twitter.com/manycontent" target="_blank" rel="noopener noreferrer">
          <i class="fab fa-twitter"></i>
        </a>
      </div>
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  setup () {
    

    return {}
  }
})
</script>

<style scoped>

</style>